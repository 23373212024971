
<template>
  <div>
    <page-snippet name="optin-out" />

    <b-table-simple v-if="outlets != null">
      <tbody>
        <template v-for="(outlet, outletId, index) in outlets">
          <tr :key="outletId + 'a'" v-if="index != 0">
            <td colspan="2">
              &nbsp;
            </td>
          </tr>
          <tr :key="outletId + 'b'">
            <td colspan="2">
              <img v-if="outlet.LogoURL" :src="outlet.LogoURL" :alt="outlet.DisplayName" class="portal-logo mb-1"  />
              <h2 v-if="!outlet.LogoURL">{{ outlet.DisplayName}}</h2> 
              <p>{{ outlet.PublicProfile }}</p>
            </td>
          </tr>
          <tr v-for="item in outlet.items" :key="item.DeliveryConsentID">
            <td class="align-middle">
              {{ item.Provider.Description }}
              <template v-if="item.SubProviderID"> / {{ item.SubProvider.Description }}</template>
            </td>
            <td>
              <template v-if="!itemsChanged[item.DeliveryConsentID]">
                <b-button class="text-nowrap mr-2" variant="success" @click="currentDc = item.DeliveryConsentID; optinModal = true">Opt-In</b-button>
                <b-button class="text-nowrap" variant="danger" @click="currentDc = item.DeliveryConsentID; optoutModal = true">Opt-Out</b-button>
              </template>

              <template v-if="itemsChanged[item.DeliveryConsentID] == 'pending'">
                <b-spinner label="Spinning"></b-spinner>
              </template>

              <template v-if="itemsChanged[item.DeliveryConsentID] == 'OptedIn'">
                <CAlert color="primary">Opted In</CAlert>
              </template>

              <template v-if="itemsChanged[item.DeliveryConsentID] == 'OptedOut'">
                <CAlert color="primary">Opted Out</CAlert>
              </template>

              <template v-if="itemsChanged[item.DeliveryConsentID] == 'error'">
                <CAlert color="warning">An error has occurred. Please reload the page and try again.</CAlert>
              </template>
            </td>
          </tr>
        </template>
      </tbody>
    </b-table-simple>

    <CModal
      title="Opt-In"
      :show.sync="optinModal"
      @update:show="updateOptin"
      size="xl"
    >
      <page-snippet name="optin" />
    </CModal>

    <CModal
      title="Opt-Out"
      :show.sync="optoutModal"
      @update:show="updateOptout"
      size="xl"
    >
      <page-snippet name="optout" />
    </CModal>
  </div>
</template>

<script>
  export default {
    name: 'DeliveryConsentList',
    components: {
      'page-snippet': () => import('@/components/PageSnippet'),
    },
    data () {
      return {
        loading: false,
        optinModal: false,
        optoutModal: false,
        itemsChanged: {},
        currentDc: null,
      }
    },
    props: {
    },
    computed: {
      items() {
        return this.$root.$data.me.deliveryConsent
      },
      outlets() {
        let out = {}
        console.log('test')

        this.$root.$data.me.deliveryConsent.forEach(dc => {
          if (!(dc.OutletID in out)) {
            out[dc.OutletID] = {...dc.Outlet}
            console.log({...dc.Outlet})
            out[dc.OutletID].items = []
          } 

          out[dc.OutletID].items.push(dc)
        })

        return out
      },
    },
    methods: {
      loadOutlets () {
        this.loading = true
      },
      updateOptin(show, event, accept) {
        if (!accept) return
        this.setStatus(1)
      },
      updateOptout(show, event, accept) {
        if (!accept) return
        this.setStatus(2)
      },
      setStatus(statusId) {
        this.$set(this.itemsChanged, this.currentDc, 'pending')

        this.$http
          .put('delivery-consent/'+this.currentDc, {StatusID: statusId})
          .then(response => {
            let dc = response.data
            let idx = this.$root.$data.me.deliveryConsent.findIndex(o => { return o.DeliveryConsentID == this.currentDc })
            this.$set(this.$root.$data.me.deliveryConsent, idx, dc)
            this.$set(this.itemsChanged, this.currentDc, dc.Status.Description)
          }).catch(() => {
            this.$set(this.itemsChanged, this.currentDc, 'error')
          }).finally(() => {
          })
      }
    },
    watch: {
    },
    mounted () {
      //this.loadOutlets()
    }
  }
</script>

<style lang="scss" scoped>
  .portal-logo {
    max-height: 100px;
    max-width: 100%;
  } 

</style>