var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("page-snippet", { attrs: { name: "optin-out" } }),
      _vm.outlets != null
        ? _c("b-table-simple", [
            _c(
              "tbody",
              [
                _vm._l(_vm.outlets, function(outlet, outletId, index) {
                  return [
                    index != 0
                      ? _c("tr", { key: outletId + "a" }, [
                          _c("td", { attrs: { colspan: "2" } })
                        ])
                      : _vm._e(),
                    _c("tr", { key: outletId + "b" }, [
                      _c("td", { attrs: { colspan: "2" } }, [
                        outlet.LogoURL
                          ? _c("img", {
                              staticClass: "portal-logo mb-1",
                              attrs: {
                                src: outlet.LogoURL,
                                alt: outlet.DisplayName
                              }
                            })
                          : _vm._e(),
                        !outlet.LogoURL
                          ? _c("h2", [_vm._v(_vm._s(outlet.DisplayName))])
                          : _vm._e(),
                        _c("p", [_vm._v(_vm._s(outlet.PublicProfile))])
                      ])
                    ]),
                    _vm._l(outlet.items, function(item) {
                      return _c("tr", { key: item.DeliveryConsentID }, [
                        _c(
                          "td",
                          { staticClass: "align-middle" },
                          [
                            _vm._v(
                              " " + _vm._s(item.Provider.Description) + " "
                            ),
                            item.SubProviderID
                              ? [
                                  _vm._v(
                                    " / " + _vm._s(item.SubProvider.Description)
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "td",
                          [
                            !_vm.itemsChanged[item.DeliveryConsentID]
                              ? [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "text-nowrap mr-2",
                                      attrs: { variant: "success" },
                                      on: {
                                        click: function($event) {
                                          _vm.currentDc = item.DeliveryConsentID
                                          _vm.optinModal = true
                                        }
                                      }
                                    },
                                    [_vm._v("Opt-In")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "text-nowrap",
                                      attrs: { variant: "danger" },
                                      on: {
                                        click: function($event) {
                                          _vm.currentDc = item.DeliveryConsentID
                                          _vm.optoutModal = true
                                        }
                                      }
                                    },
                                    [_vm._v("Opt-Out")]
                                  )
                                ]
                              : _vm._e(),
                            _vm.itemsChanged[item.DeliveryConsentID] ==
                            "pending"
                              ? [
                                  _c("b-spinner", {
                                    attrs: { label: "Spinning" }
                                  })
                                ]
                              : _vm._e(),
                            _vm.itemsChanged[item.DeliveryConsentID] ==
                            "OptedIn"
                              ? [
                                  _c(
                                    "CAlert",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("Opted In")]
                                  )
                                ]
                              : _vm._e(),
                            _vm.itemsChanged[item.DeliveryConsentID] ==
                            "OptedOut"
                              ? [
                                  _c(
                                    "CAlert",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("Opted Out")]
                                  )
                                ]
                              : _vm._e(),
                            _vm.itemsChanged[item.DeliveryConsentID] == "error"
                              ? [
                                  _c(
                                    "CAlert",
                                    { attrs: { color: "warning" } },
                                    [
                                      _vm._v(
                                        "An error has occurred. Please reload the page and try again."
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    })
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _c(
        "CModal",
        {
          attrs: { title: "Opt-In", show: _vm.optinModal, size: "xl" },
          on: {
            "update:show": [
              function($event) {
                _vm.optinModal = $event
              },
              _vm.updateOptin
            ]
          }
        },
        [_c("page-snippet", { attrs: { name: "optin" } })],
        1
      ),
      _c(
        "CModal",
        {
          attrs: { title: "Opt-Out", show: _vm.optoutModal, size: "xl" },
          on: {
            "update:show": [
              function($event) {
                _vm.optoutModal = $event
              },
              _vm.updateOptout
            ]
          }
        },
        [_c("page-snippet", { attrs: { name: "optout" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }